import { Modal, Button, Form, notification } from "antd";

import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";

export default function ModalAddUniversities(props) {
	const { toggleModalUniversities, setToggleModalUniversities } = props;
	const [form] = Form.useForm();

	const { mutate: mutateEmail, loading: loadingEmail } = POST(
		`api/ref_universities`,
		"add_university"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				toggleModalUniversities.data && toggleModalUniversities.data.id
					? toggleModalUniversities.data.id
					: "",
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "University Added",
						description: res.message,
					});

					setToggleModalUniversities({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "University not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalUniversities.open) {
			form.setFieldsValue({
				...toggleModalUniversities.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalUniversities]);

	return (
		<Modal
			title="Add University"
			open={toggleModalUniversities.open}
			onCancel={() => {
				setToggleModalUniversities({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalUniversities({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="university_name">
					<FloatInput
						label="University Name"
						placeholder="University Name"
						required
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
