import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Row, Col, Button, Form, Collapse, Image, notification } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInputMask from "../../../providers/FloatInputMask";

import { GET, POST } from "../../../providers/useAxiosQuery";

import validateRules from "../../../providers/validateRules";
import ModalFormEmail from "./ModalFormEmail";
import ModalFormPassword from "./ModalFormPassword";

import ModalUserUploadPictureForm from "./ModalUserUploadPictureForm";
import {
	apiUrl,
	defaultProfile,
	userData,
} from "../../../providers/companyInfo";
import notificationErrors from "../../../providers/notificationErrors";
import { debounce } from "lodash";

export default function PageEditProfile() {
	const params = useParams();
	const location = useLocation();
	console.log("location", location);

	const [form] = Form.useForm();

	const [userId, setUserId] = useState(
		location.pathname === "/edit-profile" ? userData().id : params.id
	);

	useEffect(() => {
		setUserId(
			location.pathname === "/edit-profile" ? userData().id : params.id
		);
	}, [location]);

	const [selectedData, setSelectedData] = useState({
		username: "",
		email: "",
		department_id: "",
		school_id: "",
		firstname: "",
		middlename: "",
		lastname: "",
		suffix: "",
		contact: "",
		course: "",
	});

	const { data: roles } = GET(`api/user_roles`, "user_role");
	const { data: course } = GET(`api/ref_courses`, "ref_courses");

	const [toggleModalFormEmail, setToggleModalFormEmail] = useState({
		open: false,
		data: null,
	});

	const [toggleModalFormPassword, setToggleModalFormPassword] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalUserUploadPictureForm,
		setToggleModalUserUploadPictureForm,
	] = useState({
		open: false,
		data: null,
	});

	const [fileList, setFileList] = useState({
		imageUrl: defaultProfile,
		loading: false,
		file: null,
	});

	GET(`api/users/${userId}`, "users_info", (res) => {
		if (res.data) {
			let data = res.data;

			let username = data.username;
			let user_role_id = data.user_role_id;
			let email = data.email;
			let school_id = data.profile.school_id;
			let firstname = data.profile.firstname;
			let middlename = data.profile.middlename;
			let lastname = data.profile.lastname;
			let suffix = data.profile.suffix;
			let course_id = data.profile.course_id;
			let contact = data.profile.contact;

			let department_id = "";

			if (
				data.profile &&
				data.profile.profile_departments &&
				data.profile.profile_departments.length
			) {
				department_id = data.profile.profile_departments[0].department_id;
			}

			if (
				data.profile &&
				data.profile.attachments &&
				data.profile.attachments.length > 0
			) {
				setFileList({
					imageUrl: apiUrl(data.profile.attachments[0].file_path),
					loading: false,
					file: null,
				});
			}

			setSelectedData({
				username,
				email,
				user_role_id,
				department_id,
				school_id,
				firstname,
				middlename,
				lastname,
				suffix,
				contact,
				course_id,
			});
			form.setFieldsValue({
				username,
				email,
				user_role_id,
				department_id,
				school_id,
				firstname,
				middlename,
				lastname,
				suffix,
				contact,
				course_id,
			});
		}
	});

	const { mutate: mutateUpdateInfo } = POST(
		`api/user_profile_info_update`,
		"users_list"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,
			contact: values.contact ? values.contact.split(" ").join("") : "",
			profile_id: params.id,
		};

		mutateUpdateInfo(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "User",
						description: res.message,
					});
				} else {
					notification.error({
						message: "User",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleTriggerDebounce = debounce((field, value) => {
		console.log("field, value", field, value, selectedData[field]);
		let oldValue = selectedData[field];
		if (field === "contact_number") {
			if (!oldValue) {
				oldValue = "";
			}
			if (value) {
				value = value.split("_").join("");
				value = value.split(" ").join("");

				if (oldValue !== value) {
					form.submit();
				}
			} else {
				if (oldValue !== value) {
					form.submit();
				}
			}
		} else {
			if (oldValue !== value) {
				form.submit();
			}
		}
	}, 1000);

	const handleDebounce = useCallback(
		(field, value) => {
			handleTriggerDebounce(field, value);
		},
		[handleTriggerDebounce]
	);

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 12]}>
				<Col sm={24} md={24} lg={14} xl={14} xxl={14}>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0", "1"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "ACCOUNT INFORMATION",
								children: (
									<Row gutter={[12, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="username">
												<FloatInput
													label="Username"
													placeholder="Username"
													disabled
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="email">
												<FloatInput
													label="Email"
													placeholder="Email"
													disabled
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Button
												type="link"
												className="p-0"
												onClick={() =>
													setToggleModalFormEmail({
														open: true,
														data: {
															id: params.id,
														},
													})
												}
											>
												Change Email
											</Button>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Button
												type="link"
												className="p-0"
												onClick={() =>
													setToggleModalFormPassword({
														open: true,
														data: {
															id: params.id,
														},
													})
												}
											>
												Change Password
											</Button>
										</Col>
									</Row>
								),
							},
							{
								key: "1",
								label: "PERSONAL INFORMATION",
								children: (
									<Row gutter={[12, 12]}>
										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="school_id"
												rules={[
													{
														min: 3,
														max: 11,
														message: "Invalid School ID",
													},
													validateRules.required,
													validateRules.number,
												]}
											>
												<FloatInputMask
													label="School ID "
													placeholder="School ID "
													required
													maskType="99999999999"
													onChange={(e) => handleDebounce("school_id", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="firstname"
												rules={[validateRules.required]}
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													required
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="middlename">
												<FloatInput
													label="Middle Name"
													placeholder="Middle Name"
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="lastname"
												rules={[validateRules.required]}
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													required
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="suffix">
												<FloatInput
													label="Suffix"
													placeholder="Suffix"
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="contact"
												rules={[validateRules.required]}
											>
												<FloatInputMask
													label="Phone No"
													placeholder="Phone No"
													maskLabel="contact_number"
													maskType="(+63) 999 999 9999"
													required={true}
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="course_id"
												rules={[validateRules.required]}
											>
												<FloatSelect
													label="Course"
													placeholder="Course"
													required
													options={
														course
															? course.data.map((item) => ({
																	value: item.id,
																	label: item.course_name,
															  }))
															: []
													}
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="user_role_id"
												rules={[validateRules.required]}
											>
												<FloatSelect
													label="User Role"
													placeholder="User Role"
													required
													options={
														roles
															? roles.data.map((item) => ({
																	value: item.id,
																	label: item.role,
															  }))
															: []
													}
													onChange={(e) => handleDebounce("firstname", e)}
												/>
											</Form.Item>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>

				<Col sm={24} md={24} lg={10} xl={10} xxl={10}>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "TAKE PHOTO",
								children: (
									<Row gutter={[12, 12]}>
										<Col
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											xxl={24}
											className="text-center"
										>
											<Image
												style={{
													left: "38.75%",
													right: "61.25%",
													width: "200px",
													height: "200px",
													borderRadius: "100%",
												}}
												src={fileList.imageUrl}
											/>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>
			</Row>

			<ModalFormEmail
				toggleModalFormEmail={toggleModalFormEmail}
				setToggleModalFormEmail={setToggleModalFormEmail}
			/>

			<ModalFormPassword
				toggleModalFormPassword={toggleModalFormPassword}
				setToggleModalFormPassword={setToggleModalFormPassword}
			/>

			<ModalUserUploadPictureForm
				toggleModalUserUploadPictureForm={toggleModalUserUploadPictureForm}
				setToggleModalUserUploadPictureForm={
					setToggleModalUserUploadPictureForm
				}
			/>
		</Form>
	);
}
