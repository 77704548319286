import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import {
	Button,
	Card,
	Col,
	Collapse,
	Descriptions,
	Empty,
	Layout,
	Row,
	Typography,
} from "antd";

import { GET } from "../../../providers/useAxiosQuery";
import { name } from "../../../providers/companyInfo";
import { apiUrl } from "../../../providers/companyInfo";
import ModalLogin from "./components/ModalLogin";

export default function PageSearchedItem() {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [items, setItems] = useState([]);

	const [toggleModalLogin, setToggleModalLogin] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		sort_field: "created_at",
		sort_order: "desc",
	});

	useEffect(() => {
		setTableFilter({
			sort_field: "created_at",
			sort_order: "desc",
		});

		return () => {};
	}, [location]);

	const { refetch: refetchBookInfo, isLoading: loadingSearchedPage } = GET(
		`api/landing_page?${new URLSearchParams(tableFilter)}`,
		["books_list"],
		(res) => {
			let data = res.data;

			let books = null;
			if (data && data.length > 0) {
				books = data
					.filter((item) => item.id === Number(params.id))
					.map((item, index) => ({
						key: index.toString(),
						label: item.bookname,
						children: (
							<Descriptions
								title={null}
								bordered
								size="small"
								column={{
									xs: 1,
									sm: 1,
									md: 1,
									lg: 1,
									xl: 1,
									xxl: 1,
								}}
								items={[
									{
										key: "1",
										label: "AUTHORS",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: item.authors.map((author) => (
											<div key={author.profile.id}>
												{author.profile.firstname} {author.profile.lastname}
											</div>
										)),
									},
									{
										key: "2",
										label: "YEAR PUBLISHED",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: item.year_publish_formatted,
									},
									{
										key: "3",
										label: "ABSTRACT",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: (
											<div
												className="quill-output"
												dangerouslySetInnerHTML={{
													__html: item.abstract,
												}}
											/>
										),
									},
									{
										key: "4",
										label: "PROGRAM DEPARTMENT",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: item.department_name,
									},
									{
										key: "5",
										label: "UNIVERSITY",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: item.university_name,
									},
									{
										key: "6",
										label: "MATERIAL TYPE",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: item.type_text,
									},
									{
										key: "7",
										label: "PUBLISHABLE PAPER",
										labelStyle: {
											width: 200,
											textAlign: "right",
										},
										span: {
											xs: 1,
											sm: 1,
											md: 1,
											lg: 1,
											xl: 1,
											xxl: 1,
										},
										children: (
											<div>
												{item.attachments.map((attachment) => (
													<div key={attachment.file_name}>
														<Button
															type="link"
															onClick={() => {
																window.open(
																	apiUrl(attachment.file_path),
																	"_blank"
																);
															}}
														>
															{attachment.file_name}
														</Button>
													</div>
												))}
											</div>
										),
									},
								]}
							/>
						),
					}));
				setItems(books);
			}
		}
	);

	useEffect(() => {
		refetchBookInfo();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const scrollToSection = (id) => {
		const yOffset = -100;
		const element = document.getElementById(id);
		if (element) {
			let top = element.getBoundingClientRect().top;
			const y = top + window.scrollY + yOffset;
			window.scrollTo({ top: y, behavior: "smooth" });
		}
	};

	const navigateToHome = () => {
		navigate("/");
	};

	return (
		<>
			<Layout class="PageSearchedItem">
				<Layout.Header>
					<div className="left-menu">
						<img
							className="header_logo"
							src="/assets/images/logo_sidemenu.png"
							alt=""
						/>
					</div>
					<div className="right-menu">
						<button className="header-link" onClick={() => navigate("/")}>
							Home
						</button>
						<button className="header-link" onClick={() => navigate("/")}>
							About
						</button>
						<button className="header-link" onClick={() => navigate("/")}>
							Contact
						</button>
						<button className="header-link" onClick={() => navigate("/")}>
							Featured
						</button>
					</div>
				</Layout.Header>
				<Layout.Content>
					<Card>
						<Row>
							<Typography.Title level={3}>Thesis Details</Typography.Title>
						</Row>
						<Row gutter={[12, 12]}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{items.length > 0 ? (
									<Collapse
										accordion
										items={items}
										expandIcon={({ isActive }) => (
											<FontAwesomeIcon
												icon={isActive ? faAngleUp : faAngleDown}
											/>
										)}
										defaultActiveKey={["0"]}
										expandIconPosition="end"
										isLoading={loadingSearchedPage}
									/>
								) : (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description="No Data"
									/>
								)}
							</Col>
						</Row>
					</Card>
				</Layout.Content>
				<Layout.Footer>{`${name} ©2024 DEVELOPED BY DSAC TEAM`}</Layout.Footer>
			</Layout>
		</>
	);
}
