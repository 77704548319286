import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Row,
	Button,
	Col,
	Table,
	notification,
	Popconfirm,
	Select,
	Form,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";

import { GET, DELETE } from "../../../providers/useAxiosQuery";
import {
	TableGlobalFilter,
	TableGlobalSearch,
	TableGlobalYearFilter,
	TablePageSize,
	TablePagination,
	TableShowingEntries,
} from "../../../providers/CustomTableFilter";

import ModalAttachmentPreview from "./components/ModalAttachmentPreview";
import notificationErrors from "../../../providers/notificationErrors";

export default function TableThesis(props) {
	const {
		dataSource,
		tableFilter,
		setTableFilter,
		location,
		placeholder,
		size,
		className,
		dataDepartments,
	} = props;

	const [toggleModalPreviewPdf, setToggleModalPreviewPdf] = useState({
		open: false,
		data: null,
	});

	const navigate = useNavigate();

	// const { data: dataUsers } = GET(`api/books`, "books");

	const { mutate: mutatehandleDelete, isLoading: loadinghandleDelete } = DELETE(
		`api/books`,
		"books_list"
	);

	const handleDelete = (record) => {
		mutatehandleDelete(record, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Books",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<div className="tbl-top-filter">
						<div>
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
						<div className="tbl-sidetop-filter">
							<TableGlobalYearFilter
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
							<TableGlobalFilter
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								filter_key_name="department_id"
								options={dataDepartments}
								placeholder="Select Department"
								allowClear={true}
							/>

							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<Table
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						onChange={onChangeTable}
						scroll={{ x: "max-content" }}
					>
						{location.pathname !== "/thesis/archived" && (
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={150}
								render={(text, record) => {
									console.log("recorddsdgdsgs", record);
									return (
										<>
											<Button
												type="link"
												className="color-1"
												onClick={() =>
													navigate(`${location.pathname}/edit/${record.id}`)
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
											<Popconfirm
												title="Are you sure to deactivate this data?"
												onConfirm={() => {
													handleDelete(record);
												}}
												onCancel={() => {
													notification.error({
														message: "Thesis Title Deactivate",
														description: "Thesis Title was not deactivated",
													});
												}}
												okText="Yes"
												cancelText="No"
											>
												<Button
													type="link"
													className="text-danger"
													loading={loadinghandleDelete}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Popconfirm>
										</>
									);
								}}
							/>
						)}

						<Table.Column
							title="Department"
							key="department_id"
							dataIndex="department_id"
							render={(text, record) => {
								return <>{record.department_name}</>;
							}}
						/>
						<Table.Column
							title="Book Title"
							key="bookname"
							dataIndex="bookname"
						/>

						<Table.Column
							title="Authors"
							key="profile_id"
							dataIndex="profile_id"
							render={(text, record) => {
								console.log("record: ", record);
								return record.authors.map((item) => {
									let lastname = null;
									let firstname = null;
									let middlename = null;

									if (item && item.profile) {
										lastname = item.profile.lastname;
										firstname = item.profile.firstname;
										middlename = item.profile.middlename;
									}

									return (
										<div>{`${lastname}, ${firstname} ${middlename}.`}</div>
									);
								});
							}}
						/>

						<Table.Column
							title="Year Published"
							key="year_publish_formatted"
							dataIndex="year_publish_formatted"
						/>
						<Table.Column
							title="Type of Text"
							key="type_id"
							dataIndex="type_id"
							render={(text, record) => {
								// console.log("records", record);
								return <>{record.type_text}</>;
							}}
						/>

						<Table.Column
							title="Publishable Paper"
							key="pdf_file"
							render={(_, record) => {
								let attachment = null;

								if (record.attachments && record.attachments.length > 0) {
									attachment = record.attachments[0];
								}

								return (
									<Button
										type="link"
										className="color-1"
										onClick={() =>
											setToggleModalPreviewPdf({
												open: true,
												data: attachment,
											})
										}
									>
										<FontAwesomeIcon icon={faEye} />
									</Button>
								);
							}}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntries />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							// setPaginationTotal={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</div>
				</Col>
			</Row>

			<ModalAttachmentPreview
				toggleModalPreviewPdf={toggleModalPreviewPdf}
				setToggleModalPreviewPdf={setToggleModalPreviewPdf}
			/>
		</>
	);
}
