import { useParams } from "react-router-dom";
import {
	Card,
	Col,
	Row,
	Button,
	Form,
	Typography,
	notification,
	Avatar,
	Collapse,
	Image,
} from "antd";
import React, { Component, useState } from "react";

import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatDatePicker from "../../../providers/FloatDatePicker";
import FloatInputMask from "../../../providers/FloatInputMask";
import notificationErrors from "../../../providers/notificationErrors";
import validateRules from "../../../providers/validateRules";

import optionGender from "../../../providers/optionGender";
import optionLanguage from "../../../providers/optionLanguage";
import nationalities from "../../../providers/nationalities";

import { DELETE, GET, POST } from "../../../providers/useAxiosQuery";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import ModalUserUploadPictureForm from "../PageEditProfile/ModalUserUploadPictureForm";
import { defaultProfile } from "../../../providers/companyInfo";

const { Title } = Typography;

export default function PageProfiling() {
	const params = useParams();
	const [src] = useState("");

	const onChange = (date, dateString) => {
		console.log(date, dateString);
	};

	const [form] = Form.useForm();

	const { data: roles } = GET(`api/user_roles`, "user_role");
	const { data: course } = GET(`api/ref_courses`, "ref_courses");
	const { data: department } = GET(`api/ref_departments`, "ref_departments");

	const { mutate: mutateProfileCreate, loading: loadingProfile } = POST(
		`api/users`,
		"users_list"
	);

	const [
		toggleModalUserUploadPictureForm,
		setToggleModalUserUploadPictureForm,
	] = useState({
		open: false,
		data: null,
	});

	const [fileList, setFileList] = useState({
		imageUrl: defaultProfile,
		loading: false,
		file: null,
	});

	const onFinish = async (values) => {
		let data = new FormData();

		console.log("data", data);

		// let data = { ...values };
		data.append("id", params.id ? params.id : "");
		data.append("username", values.username);
		data.append("email", values.email);
		data.append("user_role_id", values.user_role_id);

		if (!params.id) {
			data.append("password", values.password);
		}

		data.append("firstname", values.firstname);
		data.append("middlename", values.middlename);
		data.append("lastname", values.lastname);
		data.append("suffix", values.suffix);
		data.append("school_id", values.school_id);
		data.append("contact", values.contact);
		data.append("course_id", values.course_id);
		data.append("department_id", values.department_id);

		mutateProfileCreate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Users",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 12]}>
				<Col sm={24} md={24} lg={14} xl={14} xxl={14}>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0", "1"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "ACCOUNT INFORMATION",
								children: (
									<Row gutter={[12, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="username">
												<FloatInput
													label="Username"
													placeholder="Username"
													required
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="email"
												rules={[validateRules.required, validateRules.email]}
											>
												<FloatInput
													label="Email"
													placeholder="Email"
													required
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="password"
												rules={[validateRules.required, validateRules.password]}
											>
												<FloatInput
													label="Password"
													placeholder="Password"
													required
												/>
											</Form.Item>
										</Col>
									</Row>
								),
							},
							{
								key: "1",
								label: "PERSONAL INFORMATION",
								children: (
									<Row gutter={[12, 12]}>
										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="school_id"
												rules={[
													{
														min: 3,
														max: 11,
														message: "Invalid School ID",
													},
													validateRules.required,
													validateRules.number,
												]}
											>
												<FloatInputMask
													label="School ID "
													placeholder="School ID "
													required
													maskType="99999999999"
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="firstname"
												rules={[validateRules.required]}
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													required
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="middlename">
												<FloatInput
													label="Middle Name"
													placeholder="Middle Name"
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="lastname"
												rules={[validateRules.required]}
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													required
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="suffix">
												<FloatInput label="Suffix" placeholder="Suffix" />
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="contact"
												rules={[validateRules.required]}
											>
												<FloatInputMask
													label="Phone No"
													placeholder="Phone No"
													maskLabel="contact_number"
													maskType="(+63) 999 999 9999"
													required={true}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="course_id"
												rules={[validateRules.required]}
											>
												<FloatSelect
													label="Course"
													placeholder="Course"
													required
													options={
														course
															? course.data.map((item) => ({
																	value: item.id,
																	label: item.course_name,
															  }))
															: []
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="department_id"
												rules={[validateRules.required]}
											>
												<FloatSelect
													label="Department"
													placeholder="Department"
													required
													options={
														department
															? department.data.map((item) => ({
																	value: item.id,
																	label: item.department_name,
															  }))
															: []
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="user_role_id"
												rules={[validateRules.required]}
											>
												<FloatSelect
													label="User Role"
													placeholder="User Role"
													required
													options={
														roles
															? roles.data.map((item) => ({
																	value: item.id,
																	label: item.role,
															  }))
															: []
													}
												/>
											</Form.Item>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>

				<Col sm={24} md={24} lg={10} xl={10} xxl={10}>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "TAKE PHOTO",
								children: (
									<Row gutter={[12, 12]}>
										<Col
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											xxl={24}
											className="text-center"
										>
											<Image
												style={{
													left: "38.75%",
													right: "61.25%",
													width: "200px",
													height: "200px",
													borderRadius: "100%",
												}}
												src={fileList.imageUrl}
											/>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>
			</Row>

			<Button
				htmlType="submit"
				className="btn-main-primary w-10 w-xs-100"
				style={{ marginTop: "10px" }}
			>
				Submit
			</Button>

			<ModalUserUploadPictureForm
				toggleModalUserUploadPictureForm={toggleModalUserUploadPictureForm}
				setToggleModalUserUploadPictureForm={
					setToggleModalUserUploadPictureForm
				}
			/>
		</Form>
	);
}
