import { Modal, Button, Form, notification } from "antd";

import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";

export default function ModalAddDepartment(props) {
	const { toggleModalAddDepartment, setToggleModalAddDepartments } = props;
	const [form] = Form.useForm();

	const { mutate: mutateEmail, loading: loadingEmail } = POST(
		`api/ref_departments`,
		"add_departments"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				toggleModalAddDepartment.data && toggleModalAddDepartment.data.id
					? toggleModalAddDepartment.data.id
					: "",
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Department Added",
						description: res.message,
					});

					setToggleModalAddDepartments({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Department not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalAddDepartment.open) {
			form.setFieldsValue({
				...toggleModalAddDepartment.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalAddDepartment]);

	return (
		<Modal
			title="Add Department"
			open={toggleModalAddDepartment.open}
			onCancel={() => {
				setToggleModalAddDepartments({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalAddDepartments({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="department_name">
					<FloatInput label="Department" placeholder="Department" required />
				</Form.Item>
			</Form>
		</Modal>
	);
}
