import { Modal, Button, Form, notification } from "antd";

import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";
import { POST } from "../../../../providers/useAxiosQuery";

export default function ModalLogin(props) {
	const { toggleModalLogin, setToggleModalLogin } = props;
	const [form] = Form.useForm();

	const { mutate: mutateEmail, loading: loadingEmail } = POST(
		`api/users`,
		"users"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				toggleModalLogin.data && toggleModalLogin.data.id
					? toggleModalLogin.data.id
					: "",
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Course Added",
						description: res.message,
					});

					setToggleModalLogin({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Course not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalLogin.open) {
			form.setFieldsValue({
				...toggleModalLogin.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalLogin]);

	return (
		<Modal
			title="Log in"
			open={toggleModalLogin.open}
			onCancel={() => {
				setToggleModalLogin({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalLogin({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					LOGIN
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="email">
					<FloatInput label="Email" placeholder="Email" required />
				</Form.Item>
				<Form.Item name="password">
					<FloatInput label="Password" placeholder="Password" required />
				</Form.Item>
			</Form>
		</Modal>
	);
}
