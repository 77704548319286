import React from "react";
import { Tabs } from "antd/lib";

import TableAuthorRoles from "../PageRef/PageRefTabs/TableAuthorRoles";
import TableUniversities from "./PageRefTabs/TableUniversities";
import TableTypeofText from "./PageRefTabs/TableTypeofText";
import TableCourses from "./PageRefTabs/TableCourses";
import TableDepartment from "./PageRefTabs/TableDepartment";

// import PageExamCategory from "../PageExamCategory/PageExamCategory";

export default function PageSettings() {
	const tabListTitle = [
		{
			key: "1",
			label: "Author Roles",
			children: <TableAuthorRoles />,
			id: "Author Roles",
		},
		{
			key: "2",
			label: "Course",
			children: <TableCourses />,
			id: "Course",
		},
		{
			key: "3",
			label: "Department",
			children: <TableDepartment />,
			id: "Department",
		},
		{
			key: "4",
			label: "Type of Text",
			children: <TableTypeofText />,
			id: "Type of Text",
		},

		{
			key: "5",
			label: "Universities",
			children: <TableUniversities />,
			id: "Universities",
		},
	];

	const onChange = (key) => {
		console.log(key);
	};

	return (
		<Tabs
			defaultActiveKey="1"
			onChange={onChange}
			type="card"
			items={tabListTitle}
		/>
	);
}
