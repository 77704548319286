import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, DatePicker, Layout, List, Row, Select } from "antd";
import dayjs from "dayjs";

import { GET } from "../../../providers/useAxiosQuery";
import { name } from "../../../providers/companyInfo";
import FloatInputSearch from "../../../providers/FloatInputSearch";

const { RangePicker } = DatePicker;

export default function SearchHomePage() {
	const { data: department } = GET(`api/departments`, "ref_departments");

	const navigate = useNavigate();
	const location = useLocation();

	const urlParams = new URLSearchParams(location.search);

	const [searchQuery, setSearchQuery] = useState(
		urlParams.get("qs") ? urlParams.get("qs") : ""
	);

	const [yearRange, setYearRange] = useState([]);

	console.log("yearRange: ", yearRange);

	const [filterDepartment, setDepartment] = useState(null);

	const {
		data: dataSource,
		isLoading: loadingLandingPage,
		refetch: refetchDataSource,
	} = GET(
		`api/landing_page?search=${searchQuery}&year_range=${yearRange.join(
			","
		)}&department_id=${filterDepartment || ""}`,
		"books_list"
	);

	const handleSearchChange = (e) => {
		setSearchQuery(e);

		setTimeout(() => {
			refetchDataSource();
		}, 500);
	};

	const scrollToSection = (id) => {
		const yOffset = -100;
		const element = document.getElementById(id);
		if (element) {
			let top = element.getBoundingClientRect().top;
			const y = top + window.scrollY + yOffset;
			window.scrollTo({ top: y, behavior: "smooth" });
		}
	};

	const navigateToHome = () => {
		navigate("/");
	};

	return (
		<Layout class="SearchHomePage">
			<Layout.Header>
				<div className="left-menu">
					<img
						className="header_logo"
						src="/assets/images/logo_sidemenu.png"
						alt=""
					/>
				</div>
				<div className="right-menu">
					<button className="header-link" onClick={() => navigate("/")}>
						Home
					</button>
					<button className="header-link" onClick={() => navigate("/")}>
						About
					</button>
					<button className="header-link" onClick={() => navigate("/")}>
						Contact
					</button>
					<button className="header-link" onClick={() => navigate("/")}>
						Featured
					</button>
				</div>
			</Layout.Header>
			<Layout.Content>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={24} lg={6}>
						<Card>
							<Col>
								<div>Year Range:</div>
								<RangePicker
									size="large"
									picker="year"
									id={{
										start: "startInput",
										end: "endInput",
									}}
									onFocus={(_, info) => {
										console.log("Focus:", info.range);
									}}
									onBlur={(_, info) => {
										console.log("Blur:", info.range);
									}}
									value={
										yearRange.length === 0
											? []
											: [dayjs(yearRange[0]), dayjs(yearRange[1])]
									}
									onChange={(dates, dateStrings) => {
										console.log("onChange:", dates, dateStrings);
										if (dateStrings.length) {
											if (dateStrings[0] && dateStrings[1]) {
												setYearRange(dateStrings);
											} else {
												setYearRange([]);
											}
										} else {
											setYearRange([]);
										}
										setTimeout(() => {
											refetchDataSource();
										}, 500);
									}}
								/>
							</Col>
							<Col style={{ marginTop: "20px" }}>
								<div>Department:</div>
								<Select
									label="Department"
									placeholder="Department"
									style={{ width: "310px" }}
									size="large"
									options={
										department && department.data
											? department.data.map((item) => ({
													value: item.id,
													label: item.department_name,
											  }))
											: []
									}
									onChange={(e) => {
										console.log("eeeeee:", e);
										setDepartment(e);

										setTimeout(() => {
											refetchDataSource();
										}, 500);
									}}
									allowClear
								/>
							</Col>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={18}>
						<Card>
							<FloatInputSearch
								className="Search"
								label="Search Education Resources"
								placeholder="Search Education Resources"
								value={searchQuery}
								onChange={handleSearchChange}
								isLoading={loadingLandingPage}
							/>
						</Card>

						<Card>
							<List
								itemLayout="vertical"
								size="large"
								pagination={{
									onChange: (page) => {
										console.log(page);
									},
									pageSize: 3,
								}}
								dataSource={dataSource && dataSource.data}
								renderItem={(item) => (
									<List.Item
										key={item.bookname}
										// extra={
										// 	<img
										// 		width={272}
										// 		alt="logo"
										// 		src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
										// 	/>
										// }
									>
										<List.Item.Meta
											title={<a href={`/item/${item.id}`}>{item.bookname}</a>}
											description={
												<>
													<div>{item.year_publish_formatted}</div>
													<div>
														{item.authors && item.authors.length > 0 ? (
															<>
																<div style={{ display: "inline-block" }}>
																	{item.authors.map((author, index) => (
																		<span key={index}>
																			{index > 0 && ", "}
																			{author.profile &&
																				`${author.profile.firstname} ${author.profile.lastname}`}
																		</span>
																	))}
																</div>
															</>
														) : (
															<div>Authors: Not available</div>
														)}
													</div>
												</>
											}
										/>

										{item.abstract}
									</List.Item>
								)}
							/>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
			<Layout.Footer>{`${name} ©2024 DEVELOPED BY DSAC TEAM`}</Layout.Footer>
		</Layout>
	);
}
