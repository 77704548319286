import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Row,
	Button,
	Col,
	Table,
	notification,
	Popconfirm,
	Select,
	Form,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPencil,
	faPlus,
	faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { DELETE, GET } from "../../../../providers/useAxiosQuery";

import ModalAddAuthor from "../components/ModalAddAuthor";
import { userData } from "../../../../providers/companyInfo";
import ModalEditAuthor from "../components/ModalEditAuthor";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalAddExistingAuthor from "../components/ModalAddExistingAuthor";

export default function ThesisTableAuthorEdit(props) {
	const { book_id } = props;
	const navigate = useNavigate();
	const location = useLocation();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
		year_range: [],
		department_id: "",
		book_id: book_id,
	});

	useEffect(() => {
		setTableFilter((ps) => ({ ...ps, book_id }));
	}, [book_id]);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/authors?${new URLSearchParams(tableFilter)}`,
		`authors_list_${book_id}`
	);

	const { data: course } = GET(`api/ref_courses`, "ref_courses");
	const { data: role } = GET(`api/authors_roles`, "authors_roles");

	// console.log("course", course);
	// console.log("role", role);

	useEffect(() => {
		refetchSource();
	}, [tableFilter]);

	const [toggleModalAddAuthor, setToggleModalAddAuthor] = useState({
		open: false,
		data: null,
		book_id: null,
	});

	const [toggleModalEditAuthor, setToggleModalEditAuthor] = useState({
		open: false,
		data: null,
		book_id: null,
	});

	const [toggleModalAddExistingAuthor, setToggleModalAddExistingAuthor] =
		useState({
			open: false,
			data: null,
			book_id: null,
		});
	const { mutate: mutatehandleDelete, loadinghandleAuthorDelete } = DELETE(
		`api/authors`,
		"author_delete"
	);

	const handleAuthorDelete = (record) => {
		mutatehandleDelete(record, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Author",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<>
			<Row gutter={[12, 12]}>
				{location.pathname.includes("/thesis/books/edit") ? (
					<Col xs={24} sm={24} md={24}>
						<Button
							className="btn-main-primary btn-main-invert-outline b-r-none"
							style={{ marginBottom: "20px" }}
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={() =>
								setToggleModalAddExistingAuthor({
									open: true,
									book_id: book_id,
								})
							}
							size="large"
							name="btn_add"
						>
							Add Another Author
						</Button>
					</Col>
				) : null}
			</Row>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<Table
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						onChange={onChangeTable}
						scroll={{ x: "max-content" }}
					>
						<Table.Column
							title="Action"
							key="action"
							dataIndex="action"
							align="center"
							width={150}
							render={(text, record) => {
								// console.log("records", record);
								return (
									<>
										<Button
											type="link"
											className="color-1"
											onClick={() =>
												setToggleModalEditAuthor({
													open: true,
													data: record.profile,
													book_id: record.book_id,
												})
											}
										>
											<FontAwesomeIcon icon={faPencil} />
										</Button>
										<Popconfirm
											title="Are you sure to deactivate this data?"
											onConfirm={() => {
												handleAuthorDelete(record);
											}}
											onCancel={() => {
												notification.error({
													message: "Thesis Title Deactivate",
													description: "Thesis Title was not deactivated",
												});
											}}
											okText="Yes"
											cancelText="No"
										>
											<Button
												type="link"
												className="text-danger"
												loading={loadinghandleAuthorDelete}
											>
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</Popconfirm>
									</>
								);
							}}
						/>
						<Table.Column
							title="Fullname"
							key="profile_id"
							dataIndex="profile_id"
							render={(text, record) => {
								return (
									<>{`${record.profile.lastname}, ${record.profile.firstname} ${record.profile.middlename}.`}</>
								);
							}}
						/>

						<Table.Column
							title="Email Address"
							key="email"
							dataIndex="email"
							render={(text, record) => {
								return <>{record.profile.user.email}</>;
							}}
						/>

						<Table.Column
							title="Course"
							key="course_id"
							dataIndex="course_id"
							render={(text, record) => {
								return <>{record.profile.course_name}</>;
							}}
						/>
						<Table.Column
							title="Researcher's Role"
							key="role_id"
							dataIndex="role_id"
							render={(text, record) => {
								return <>{record.profile.authors_role}</>;
							}}
						/>
					</Table>
				</Col>
			</Row>

			<ModalAddAuthor
				toggleModalAddAuthor={toggleModalAddAuthor}
				setToggleModalAddAuthor={setToggleModalAddAuthor}
			/>

			<ModalEditAuthor
				toggleModalEditAuthor={toggleModalEditAuthor}
				setToggleModalEditAuthor={setToggleModalEditAuthor}
				dataSource={dataSource}
				tableFilter={tableFilter}
				course={course}
				role={role}
			/>

			<ModalAddExistingAuthor
				toggleModalAddExistingAuthor={toggleModalAddExistingAuthor}
				setToggleModalAddExistingAuthor={setToggleModalAddExistingAuthor}
				dataSource={dataSource}
				tableFilter={tableFilter}
			/>

			{/* <ModalAttachmentPreview
				toggleModalPreviewPdf={toggleModalPreviewPdf}
				setToggleModalPreviewPdf={setToggleModalPreviewPdf}
			/> */}
		</>
	);
}
