import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	Col,
	Form,
	Row,
	Upload,
	message,
	notification,
} from "antd";
import { debounce } from "lodash";
import dayjs from "dayjs";

import { GET, POST } from "../../../providers/useAxiosQuery";
import FloatInput from "../../../providers/FloatInput";
import FloatDatePicker from "../../../providers/FloatDatePicker";
import FloatSelect from "../../../providers/FloatSelect";
import notificationErrors from "../../../providers/notificationErrors";
import optionType from "../../../providers/optionType";
import ModalAttachmentPreview from "./components/ModalAttachmentPreview";
import ThesisTableAuthorEdit from "./PageThesisFormComponents/ThesisTableAuthorEdit";
import Title from "antd/es/typography/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowDownFromBracket,
	faArrowUpFromBracket,
	faEye,
} from "@fortawesome/pro-regular-svg-icons";
import { apiUrl } from "../../../providers/companyInfo";
import validateRules from "../../../providers/validateRules";
import FloatTextArea from "../../../providers/FloatTextArea";

export default function PageThesisEdit() {
	const location = useLocation();
	const params = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [toggleModalPreviewPdf, setToggleModalPreviewPdf] = useState({
		open: false,
		data: null,
	});

	const [formDisabled, setFormDisabled] = useState(true);
	const [selectedData, setSelectedData] = useState({});

	const { data: department } = GET(`api/ref_departments`, "ref_departments");
	const { data: type } = GET(`api/ref_text_types`, "ref_text_types");
	const { data: university } = GET(`api/ref_universities`, "ref_universities");

	GET(`api/books/${params.id}`, "book_info", (res) => {
		if (res.data) {
			let data = res.data;

			console.log("datass:", data);

			let pdf_file = null;
			if (data.attachments.length > 0) {
				let attachment = data.attachments[data.attachments.length - 1];
				console.log("attachment", attachment);

				pdf_file = {
					...attachment,
					uid: attachment.id,
					name: attachment.file_name,
					status: "done",
					url: apiUrl(attachment.file_path),
				};
			}

			let author_list = [];

			if (data.authors && data.authors.length > 0) {
				author_list = data.authors
					.filter((item) => item.status === "1")
					.map((item) => {
						let email = null;

						if (item.profile.user_id) {
							let itemUserId = item.profile.user_id;
						}

						return {
							...item,
							firstname: item.profile.firstname,
							middlename: item.profile.middlename,
							lastname: item.profile.lastname,
							suffix: item.profile.suffix,
							role: item.profile.role_id,
							course: item.profile.course_id,
							school_id: item.profile.school_id,
							contact: item.profile.school_id,
							email: item.profile.user.email,
						};
					});
			} else {
				author_list = [{}];
			}

			let department_id = null;

			if (data.ref_departments) {
				department_id = data.ref_departments.id;
			}

			setSelectedData({
				bookname: data.bookname,
				datepublish: data.datepublish,
				type_id: data.type_id,
				university_id: data.university_id,
				department_id: department_id,
				abstract: data.abstract,
				pdf_file,
			});

			form.setFieldsValue({
				bookname: data.bookname,
				datepublish: dayjs(data.datepublish),
				type_id: data.type_id,
				university_id: data.university_id,
				abstract: data.abstract,

				author_list: author_list,
				department_id: department_id,
				pdf_file: [pdf_file],
			});

			setFormDisabled(false);

			// setToggleModalEditAuthor(form.setFieldsValue);
		}
	});

	const { mutate: mutatethesis, loading: loadingthesis } = POST(
		`api/update_book`,
		"book_info"
	);

	const propsUpload = {
		accept: "application/pdf",

		beforeUpload: (file) => {
			setTimeout(() => {
				form.submit();
			}, 1000);
			return false;
		},
	};

	const onFinish = (values) => {
		console.log("values", values);
		let pathname = location.pathname.split("/");
		let data = new FormData();

		data.append("id", params.id ? params.id : "");

		// Add Book Information

		data.append("department_id", values.department_id);
		data.append("bookname", values.bookname);

		if (values.datepublish) {
			if (dayjs(values.datepublish).isValid()) {
				data.append(
					"datepublish",
					dayjs(values.datepublish).format("YYYY-MM-DD")
				);
			} else {
				data.append("datepublish", values.datepublish);
			}
		} else {
			data.append("datepublish", "");
		}
		data.append("type_id", values.type_id);
		data.append("university_id", values.university_id);
		data.append("abstract", values.abstract);

		// data.append(
		// 	"author_list",
		// 	JSON.stringify(values.author_list.length > 0 ? values.author_list : [])
		// );

		let originFileObj = values.pdf_file.filter((f) => f.originFileObj);
		if (values.pdf_file && originFileObj.length) {
			data.append(
				"pdf_file",
				originFileObj[originFileObj.length - 1].originFileObj,
				originFileObj[originFileObj.length - 1].originFileObj.name
			);
		}

		// Notification
		mutatethesis(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Thesis Book Information",
						description: res.message,
					});

					// navigate(`/thesis/books/edit/${dataBooks.id}`);
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleTriggerDebounce = debounce((field, value) => {
		console.log("field, value", field, value, selectedData[field]);
		let oldValue = selectedData[field];
		if (field === "contact_number") {
			if (!oldValue) {
				oldValue = "";
			}
			if (value) {
				value = value.split("_").join("");
				value = value.split(" ").join("");

				if (oldValue !== value) {
					form.submit();
				}
			} else {
				if (oldValue !== value) {
					form.submit();
				}
			}
		} else {
			if (oldValue !== value) {
				form.submit();
			}
		}
	}, 1000);

	const handleDebounce = useCallback(
		(field, value) => {
			handleTriggerDebounce(field, value);
		},
		[handleTriggerDebounce]
	);

	return (
		<>
			<Form
				onFinish={onFinish}
				form={form}
				initialValues={{ author_list: [{}] }}
				disabled={formDisabled}
			>
				<Card>
					<div>
						<Title level={5} style={{ marginTop: "5px" }}>
							Book Information
						</Title>
					</div>
					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="department_id" rules={[validateRules.required]}>
								<FloatSelect
									label="Department"
									placeholder="Department"
									options={
										department
											? department.data.map((item) => ({
													value: item.id,
													label: item.department_name,
											  }))
											: []
									}
									onChange={(e) => handleDebounce("department_id", e)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="bookname" rules={[validateRules.required]}>
								<FloatInput
									label="Book Title"
									placeholder="Thesis Title"
									onChange={(e) => handleDebounce("bookname", e.target.value)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item name="abstract" rules={[validateRules.required]}>
								<FloatTextArea
									label="Abstract"
									placeholder="Abstract"
									required
									onChange={(e) => handleDebounce("bookname", e.target.value)}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="datepublish" rules={[validateRules.required]}>
								<FloatDatePicker
									label="Year Published"
									placeholder="Year Published"
									format="YYYY-MM"
									picker="month"
									onChange={(e) =>
										handleDebounce("datepublish", e.target.value)
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="type_id" rules={[validateRules.required]}>
								<FloatSelect
									label="Type of Text"
									placeholder="Type of Text"
									options={
										type
											? type.data.map((item) => ({
													value: item.id,
													label: item.type_of_text,
											  }))
											: []
									}
									onChange={(e) => handleDebounce("type_id", e)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="university_id" rules={[validateRules.required]}>
								<FloatSelect
									label="University"
									placeholder="University"
									required
									options={
										university
											? university.data.map((item) => ({
													value: item.id,
													label: item.university_name,
											  }))
											: []
									}
									onChange={(e) => handleDebounce("university_id", e)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={(12, 12)} style={{ marginTop: "20px" }}>
						<Col>
							<Form.Item
								name="pdf_file"
								valuePropName="fileList"
								getValueFromEvent={(e) => {
									if (Array.isArray(e)) {
										return e;
									}

									return e?.fileList;
								}}
							>
								<Upload {...propsUpload}>
									<Button
										icon={<FontAwesomeIcon icon={faArrowDownFromBracket} />}
									>
										Upload File PDF
									</Button>
								</Upload>
							</Form.Item>
						</Col>
						<Col>
							<Button
								type="link"
								className="color-1"
								onClick={() =>
									setToggleModalPreviewPdf({
										open: true,
										data: selectedData && selectedData.pdf_file,
									})
								}
							>
								<FontAwesomeIcon icon={faEye} />
							</Button>
						</Col>
					</Row>
				</Card>

				<Card style={{ marginTop: "20px" }}>
					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<ThesisTableAuthorEdit book_id={params && params.id} />
						</Col>
					</Row>
				</Card>

				{/* <Row gutter={(12, 12)} style={{ marginTop: "20px" }}>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Form.Item name="pdf_file" valuePropName="filelist">
							<Upload {...propsUpload}>
								<Button icon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}>
									Upload File PDF
								</Button>
							</Upload>
						</Form.Item>
					</Col>
				</Row> */}

				<ModalAttachmentPreview
					toggleModalPreviewPdf={toggleModalPreviewPdf}
					setToggleModalPreviewPdf={setToggleModalPreviewPdf}
				/>

				{/* <Button htmlType="submit" className="btn-main-primary w-10 w-xs-100">
					Submit
				</Button> */}
			</Form>
		</>
	);
}
