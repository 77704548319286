import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Input, Layout, List, Row } from "antd";

import { GET } from "../../../providers/useAxiosQuery";
import { name } from "../../../providers/companyInfo";
import ModalLogin from "./components/ModalLogin";
import {
	faEnvelope,
	faMapMarkerAlt,
	faPhone,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageHome() {
	const navigate = useNavigate();

	const [toggleModalLogin, setToggleModalLogin] = useState({
		open: false,
		data: null,
	});

	const { data: dataSource } = GET(`api/landing_page`, "books_list");

	const scrollToSection = (id) => {
		const yOffset = -100;
		const element = document.getElementById(id);
		if (element) {
			let top = element.getBoundingClientRect().top;
			const y = top + window.scrollY + yOffset;
			window.scrollTo({ top: y, behavior: "smooth" });
		}
	};

	return (
		<>
			<Layout className="HomePage">
				<Layout.Header>
					<div className="left-menu">
						<img
							className="header_logo"
							src="/assets/images/logo_sidemenu.png"
							alt=""
						/>
					</div>
					<div className="right-menu">
						<button
							className="header-link"
							onClick={() => scrollToSection("home")}
						>
							Home
						</button>
						<button
							className="header-link"
							onClick={() => scrollToSection("about")}
						>
							About
						</button>
						<button
							className="header-link"
							onClick={() => scrollToSection("contact")}
						>
							Contact
						</button>
						<button
							className="header-link"
							onClick={() => scrollToSection("featured")}
						>
							Featured
						</button>
					</div>
				</Layout.Header>
				<Layout.Content>
					<div id="home" className="banner-wrapper">
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<h1>Welcome to FSUU Thesis Archives</h1>
								<p>
									Explore a collection of valuable academic research and
									resources.
								</p>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24}>
								<Input.Search
									className="Search"
									placeholder="Search Education Resources"
									onPressEnter={(e) => {
										console.log("e", e);
										navigate(`/search?qs=${e.target.value}`);
									}}
								/>
							</Col>
						</Row>
					</div>

					<div id="about" className="banner-wrapper-one">
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<h1>About Us</h1>
								<p>
									Welcome to the FSUU Thesis Archive, a comprehensive platform
									designed to facilitate access to educational resources and
									academic theses. Our system is dedicated to providing a
									centralized repository of scholarly works, making it easier
									for students, researchers, and educators to discover valuable
									academic content.
								</p>
								<Row gutter={[12, 0]} className="offered">
									<Col xs={24} sm={24} md={24} lg={10}>
										<Card className="about-card">
											<h2>What we Offer:</h2>
											<ul>
												<li>
													Explore a vast collection of academic theses covering
													diverse topics, showcasing the scholarly achievements
													of FSUU students.
												</li>
												<li>
													Effortlessly search for specific educational resources
													or theses using our intuitive search feature.
												</li>
												<li>
													Our online platform ensures convenient access to
													educational materials, fostering continuous learning
													and research.
												</li>
											</ul>
										</Card>
									</Col>
									<Col xs={24} sm={24} md={24} lg={10}>
										<Card className="about-card">
											<h2>Key Features:</h2>
											<ul>
												<li>
													Navigate through our platform seamlessly with a
													user-friendly interface designed for optimal
													usability.
												</li>
												<li>
													Engage with detailed thesis entries featuring
													comprehensive information about authors, publication
													years, and abstracts.
												</li>
											</ul>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<div id="contact" className="banner-wrapper-two">
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<h1>Contact Us</h1>
								<Row gutter={[12, 0]} className="contact">
									<Col xs={24} sm={24} md={24} lg={5}>
										<Card className="contact-card">
											<FontAwesomeIcon
												icon={faMapMarkerAlt}
												style={{ fontSize: "24px", marginRight: "12px" }}
											/>
											<br />
											Father Saturnino Urios University <br />
											San Francisco St, Butuan City, 8600 Agusan Del Norte
										</Card>
									</Col>
									<Col xs={24} sm={24} md={24} lg={5}>
										<Card className="contact-card">
											<FontAwesomeIcon
												icon={faPhone}
												style={{ fontSize: "24px", marginRight: "12px" }}
											/>
											<br />
											(085) 342 1830 <br /> +63 [085] 9759840
										</Card>
									</Col>
									<Col xs={24} sm={24} md={24} lg={5}>
										<Card className="contact-card">
											<FontAwesomeIcon
												icon={faEnvelope}
												style={{ fontSize: "24px", marginRight: "12px" }}
											/>
											<br />
											vpadmin@urios.edu.ph
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<div id="featured" className="featured_thesis">
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24}>
								<h1>Featured Thesis</h1>
								<Card>
									<List
										itemLayout="vertical"
										size="large"
										dataSource={dataSource && dataSource.data}
										renderItem={(item, index) => (
											<List.Item>
												<List.Item.Meta
													title={
														<a href={`/item/${item.id}`}>{item.bookname}</a>
													}
													description={
														<>
															<div>{item.year_publish_formatted}</div>
															<div>
																{item.authors && item.authors.length > 0 ? (
																	<>
																		<div style={{ display: "inline-block" }}>
																			{item.authors.map((author, index) => (
																				<span key={index}>
																					{index > 0 && ", "}
																					{author.profile &&
																						`${author.profile.firstname} ${author.profile.lastname}`}
																				</span>
																			))}
																		</div>
																	</>
																) : (
																	<div>Authors: Not available</div>
																)}
															</div>
														</>
													}
												/>
											</List.Item>
										)}
									/>
								</Card>
							</Col>
						</Row>
					</div>
				</Layout.Content>
				<Layout.Footer>{`${name} ©2024 DEVELOPED BY DSAC TEAM`}</Layout.Footer>

				<ModalLogin
					toggleModalLogin={toggleModalLogin}
					setToggleModalLogin={setToggleModalLogin}
				/>
			</Layout>
		</>
	);
}
