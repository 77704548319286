import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Button, Col, Table, notification, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { DELETE } from "../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntries,
} from "../../../providers/CustomTableFilter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import notificationErrors from "../../../providers/notificationErrors";

import dayjs from "dayjs";

export default function TableProfiling(props) {
	const {
		dataSource,
		tableFilter,
		setTableFilter,
		sortInfo,
		setSortInfo,
		location,
		refetchSource,
	} = props;

	const navigate = useNavigate();

	const { mutate: mutatehandleDelete, loadinghandleUsersDelete } = DELETE(
		`api/users`,
		"delete_users"
	);

	const handleUsersDelete = (record) => {
		mutatehandleDelete(record, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Books",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<div className="tbl-top-filter">
						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
						<TableGlobalSearch
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<Table
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						onChange={onChangeTable}
						scroll={{ x: "max-content" }}
					>
						{location.pathname !== "/users/archived" && (
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={150}
								render={(text, record) => {
									return (
										<>
											<Button
												type="link"
												className="color-1"
												onClick={() =>
													navigate(
														`${location.pathname}/edit-profile/${record.id}`
													)
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
											<Popconfirm
												title="Are you sure to deactivate this data?"
												onConfirm={() => {
													handleUsersDelete(record);
												}}
												onCancel={() => {
													notification.error({
														message: "Books Title Deactivate",
														description: "Books Title was not deactivated",
													});
												}}
												okText="Yes"
												cancelText="No"
											>
												<Button
													type="link"
													className="text-danger"
													loading={loadinghandleUsersDelete}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Popconfirm>
										</>
									);
								}}
							/>
						)}

						<Table.Column
							title="Username"
							key="username"
							dataIndex="username"
						/>

						<Table.Column title="Email" key="email" dataIndex="email" />

						<Table.Column
							title="Role"
							key="type"
							dataIndex="type"
							// render={(text, record) => {
							// 	// console.log("record: ", record);
							// 	return <>{record.user_role}</>;
							// }}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntries />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							// setPaginationTotal={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</div>
				</Col>
			</Row>

			{/* <ModalFormEntranceExamSchedule
				toggleModalFormEntranceExamSchedule={
					toggleModalFormEntranceExamSchedule
				}
				setToggleModalFormEntranceExamSchedule={
					setToggleModalFormEntranceExamSchedule
				}
				dataSource={dataSource}
				refetchSource={refetchSource}
			/> */}
		</>
	);
}
