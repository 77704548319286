import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, notification, Col, Row } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import ModalAddAuthor from "./ModalAddAuthor";

export default function ModalAddExistingAuthor(props) {
	const {
		toggleModalAddExistingAuthor,
		setToggleModalAddExistingAuthor,
		form1,
	} = props;
	const [form] = Form.useForm();
	const params = useParams();

	// console.log("toggleModalAddAuthorssss", toggleModalAddExistingAuthor);

	const { data: authors, refetch: refetchAuthor } = GET(
		`api/profiles`,
		"profiles"
	);

	const [toggleModalAddAuthor, setToggleModalAddAuthor] = useState({
		open: false,
		data: null,
		book_id: null,
	});

	const { mutate: mutateEmail, loading: loadingEmail } = POST(`api/authors`, [
		`add_authors`,
		`existing_authors_${toggleModalAddExistingAuthor.book_id}`,
	]);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,
			author_list: JSON.stringify(values.profile_id),

			id:
				toggleModalAddExistingAuthor.data &&
				toggleModalAddExistingAuthor.data.id
					? toggleModalAddExistingAuthor.data.id
					: "",
			book_id: toggleModalAddExistingAuthor.book_id,
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Author Added",
						description: res.message,
					});

					setToggleModalAddExistingAuthor({ open: false, data: null });

					form.resetFields();

					let profile_id = form1.getFieldValue("profile_id");
					console.log("profile_id", profile_id);
					if (profile_id) {
						profile_id.push(res.profileInfo.id);
					} else {
						profile_id = [res.profileInfo.id];
					}
					form1.setFieldValue("profile_id", profile_id);
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Author is not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalAddExistingAuthor.open) {
			form.setFieldsValue({
				...toggleModalAddExistingAuthor.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalAddExistingAuthor]);

	return (
		<Modal
			width="700px"
			title="Add Author"
			open={toggleModalAddExistingAuthor.open}
			onCancel={() => {
				setToggleModalAddExistingAuthor({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalAddExistingAuthor({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={24} lg={16}>
						<Button
							className="btn-main-primary btn-main-invert-outline b-r-none"
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={() =>
								setToggleModalAddAuthor({
									open: true,
								})
							}
							size="large"
							name="btn_add"
						>
							Add Another Author
						</Button>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} style={{ marginTop: "16px" }}>
						<Form.Item name="profile_id">
							<FloatSelect
								label="Authors"
								placeholder="Authors"
								required
								multi="multiple"
								onChange={handleChange}
								options={
									authors
										? authors.data.map((item) => ({
												value: item.id,
												label: `${item.firstname} ${
													item.middlename ? item.middlename + " " : ""
												}${item.lastname}`,
										  }))
										: []
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<ModalAddAuthor
					toggleModalAddAuthor={toggleModalAddAuthor}
					setToggleModalAddAuthor={setToggleModalAddAuthor}
					form1={form}
					refetchAuthor={refetchAuthor}
				/>
			</Form>
		</Modal>
	);
}
