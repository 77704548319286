import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, notification, Col, Row } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";

export default function ModalAddAuthor(props) {
	const {
		toggleModalAddAuthor,
		setToggleModalAddAuthor,
		form1,
		refetchAuthor,
	} = props;
	const [form] = Form.useForm();
	const params = useParams();

	const { data: course } = GET(`api/ref_courses`, "ref_courses");
	const { data: role } = GET(`api/authors_roles`, "authors_roles");

	const { mutate: mutateEmail, loading: loadingEmail } = POST(`api/profiles`, [
		`add_profiles`,
		`profiles_${toggleModalAddAuthor.book_id}`,
	]);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				toggleModalAddAuthor.data && toggleModalAddAuthor.data.id
					? toggleModalAddAuthor.data.id
					: "",
			book_id: toggleModalAddAuthor.book_id,
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Author Added",
						description: res.message,
					});

					setToggleModalAddAuthor({ open: false, data: null });

					form.resetFields();

					let profile_id = form1.getFieldValue("profile_id");
					console.log("profile_id", profile_id);
					if (profile_id) {
						profile_id.push(res.profileInfo.id);
					} else {
						profile_id = [res.profileInfo.id];
					}
					form1.setFieldValue("profile_id", profile_id);

					if (refetchAuthor) {
						refetchAuthor();
					}
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Author is not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalAddAuthor.open) {
			form.setFieldsValue({
				...toggleModalAddAuthor.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalAddAuthor]);

	return (
		<Modal
			width="900px"
			title="Add Author"
			open={toggleModalAddAuthor.open}
			onCancel={() => {
				setToggleModalAddAuthor({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalAddAuthor({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="firstname" rules={[validateRules.required]}>
							<FloatInput label="Firstname" placeholder="Firstname" required />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="middlename">
							<FloatInput label="Middlename" placeholder="Middlename" />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="lastname" rules={[validateRules.required]}>
							<FloatInput label="Lastname" placeholder="Lastname" required />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="suffix">
							<FloatInput label="Suffix" placeholder="Suffix" />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="role_id" rules={[validateRules.required]}>
							<FloatSelect
								label="Role"
								placeholder="Role"
								required
								options={
									role
										? role.data.map((item) => ({
												value: item.id,
												label: item.author_role,
										  }))
										: []
								}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item
							name="email"
							rules={[validateRules.required, validateRules.email]}
						>
							<FloatInput
								label="Email Address"
								placeholder="Email Address"
								required
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item
							name="school_id"
							rules={[
								{
									min: 3,
									max: 11,
									message: "Invalid School ID",
								},
								validateRules.required,
								validateRules.number,
							]}
						>
							<FloatInputMask
								label="School ID "
								placeholder="School ID "
								required
								maskType="99999999999"
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Form.Item name="contact" rules={[validateRules.required]}>
							<FloatInputMask
								label="Phone No"
								placeholder="Phone No"
								maskLabel="contact_number"
								maskType="(+63) 999 999 9999"
								required
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={12} lg={12} xl={24}>
						<Form.Item name="course_id" rules={[validateRules.required]}>
							<FloatSelect
								label="Course"
								placeholder="Course"
								required
								options={
									course
										? course.data.map((item) => ({
												value: item.id,
												label: item.course_name,
										  }))
										: []
								}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
