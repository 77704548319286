import { useEffect, useState } from "react";

import { GET } from "../../../../providers/useAxiosQuery";
import leftArrow from "../../../../assets/img/left-arrow.png";

export default function GraphBook(props) {
	const { Highcharts } = props;

	const [filter, setFilter] = useState({
		action: "yearly",
		year: "",
		month: "",
		department: "",
		department_id: "",
	});

	const { refetch: refetchSource } = GET(
		`api/book_graph?${new URLSearchParams(filter)}`,
		"book_graph",
		(res) => {
			console.log("book_graph", res);

			if (res.data) {
				let data = res.data;
				let action = res.data.action;

				let title = "BOOKS";
				let subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";

				if (action === "yearly") {
					subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
				} else if (action === "monthly") {
					subtitleText = "CLICK THE COLUMNS TO VIEW PER DAILY";
				} else if (action === "daily") {
					subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER YEAR";
				}

				if (document.getElementById("graphBook")) {
					let chart = Highcharts.chart(
						"graphBook",
						{
							chart: {
								type: "column",
								events: {
									click: function (e) {
										let action = data.action;
										let downTo = data.downTo;
										let series_name =
											data.data_series_name[
												Math.abs(Math.round(e.xAxis[0].value))
											];

										if (action === "yearly") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
												year: series_name,
											}));
										}

										if (action === "monthly") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
												month: series_name,
											}));
										}

										if (action === "departments") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
												department: series_name,
											}));
										}

										if (action === "books") {
											setFilter((prevState) => ({
												...prevState,
												action: downTo,
											}));
										}
									},
								},
							},
							title: {
								text: title,
							},
							subtitle: {
								text: subtitleText,
							},
							xAxis: {
								title: {
									text: "Count",
									enabled: false,
								},
								categories: data.data_series_name,
								crosshair: true,
								type: "category",
							},
							yAxis: {
								title: {
									text: null,
								},
								// labels: {
								// 	formatter: function () {
								// 		return `${Highcharts.numberFormat(this.value, 0, "", ",")}`;
								// 	},
								// },
							},
							tooltip: {
								headerFormat:
									'<span style="font-size:10px">{point.key}</span><table>',
								pointFormat:
									'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
									'<td style="padding:0"><b>{point.y}</b></td></tr>',
								footerFormat: "</table>",
								shared: true,
								useHTML: true,
							},
							legend: {
								enabled: true,
							},
							plotOptions: {
								series: {
									borderWidth: 0,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.2f}",
									},
									cursor: "pointer",
								},
								column: {
									pointPadding: 0.2,
									borderWidth: 0,
									dataLabels: {
										enabled: false,
										// format: "{point.y:.0f}",
										formatter: function () {
											if (this.y === 0) {
												return null;
											}
											return this.y.toFixed(2);
										},
									},
									events: {
										click: function (e) {
											let action = data.action;
											let downTo = data.downTo;

											if (action === "yearly") {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
													year: e.point.category,
												}));
											}

											if (action === "monthly") {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
													month: e.point.category,
												}));
											}

											if (action === "departments") {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
													department: e.point.category,
												}));
											}

											if (action === "books") {
												setFilter((prevState) => ({
													...prevState,
													action: downTo,
												}));
											}
										},
									},
								},
							},
							series: data.data_series_value,
						},
						function (chart) {
							// on complete

							if (data.action !== "yearly") {
								let y = 65;
								let x = 15;

								// console.log("x: ", x, " y: ", y);
								chart.renderer
									.image(leftArrow, chart.chartWidth - y, x, 18, 17)
									.add()
									.addClass("highcharts-button-arrow-left")
									.css({ cursor: "pointer" })
									.attr({ title: "Back" })
									.on("click", function () {
										// prcessing after image is clicked
										let action = res.data.action;

										if (action === "monthly") {
											setFilter((prevState) => ({
												...prevState,
												action: "yearly",
											}));
										}

										if (action === "departments") {
											setFilter((prevState) => ({
												...prevState,
												action: "monthly",
											}));
										}

										if (action === "books") {
											setFilter((prevState) => ({
												...prevState,
												action: "departments",
											}));
										}
									});
							}
						}
					);

					Highcharts.addEvent(chart, "aftergetTableAST", function (e) {
						e.tree.children[2].children.forEach(function (row) {
							row.children.forEach(function (cell, i) {
								if (i !== 0) {
									row.children[i].textContent = cell.textContent;
								}
							});
						});
					});
				}
			}
		}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return <div id="graphBook" />;
}
