import { Col, Collapse, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import Highcharts from "highcharts";

import GraphBook from "./components/GraphBook";

import highchartsSetOptions from "../../../providers/highchartsSetOptions";
require("highcharts/modules/accessibility")(Highcharts);
// require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	highchartsSetOptions(Highcharts);

	return (
		<Row>
			<Col xs={24} sm={24} md={24} lg={14} xl={14}>
				<Collapse
					className="collapse-main-primary"
					defaultActiveKey={["0", "1"]}
					size="middle"
					expandIcon={({ isActive }) => (
						<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
					)}
					items={[
						{
							key: "0",
							label: "BOOKS GRAPH",
							children: <GraphBook Highcharts={Highcharts} />,
						},
					]}
				/>
			</Col>
		</Row>
	);
}
