import { Modal, Button, Form, notification } from "antd";

import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";

export default function ModalAddTypeText(props) {
	const { setToggleModalTypeText, setToggleModalTypeofText } = props;
	const [form] = Form.useForm();

	const { mutate: mutateEmail, loading: loadingEmail } = POST(
		`api/ref_text_types`,
		"add_typeoftext"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				setToggleModalTypeText.data && setToggleModalTypeText.data.id
					? setToggleModalTypeText.data.id
					: "",
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Type of Text Added",
						description: res.message,
					});

					setToggleModalTypeofText({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Type of Text not Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (setToggleModalTypeText.open) {
			form.setFieldsValue({
				...setToggleModalTypeText.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setToggleModalTypeText]);

	return (
		<Modal
			title="Add Type of Text"
			open={setToggleModalTypeText.open}
			onCancel={() => {
				setToggleModalTypeofText({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalTypeofText({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="type_of_text">
					<FloatInput
						label="Type of Text"
						placeholder="Type of Text"
						required
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
