import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, notification, Col, Row } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";

export default function ModalEditTypeText(props) {
	const { toggleModalEditTypeText, setToggleModalEditTypeText } = props;

	// console.log("course", course);
	// console.log("role", role);
	const [form] = Form.useForm();
	const params = useParams();

	const { mutate: mutateEmail, loading: loadingEmail } = POST(
		`api/ref_text_types`,
		[`edit_type_text_${toggleModalEditTypeText.id}`]
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,

			id:
				toggleModalEditTypeText &&
				toggleModalEditTypeText.data &&
				toggleModalEditTypeText.data.id,

			// book_id: toggleModalEditAuthor.book_id,
		};

		mutateEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Author Added",
						description: res.message,
					});

					setToggleModalEditTypeText({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Something went wrong",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Author not successfully Added",
					description: "Something went Wrong",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalEditTypeText.open) {
			if (toggleModalEditTypeText.data) {
				let data = {
					...toggleModalEditTypeText.data,
					// email: toggleModalEditCourse.data.user.email,
				};
				form.setFieldsValue(data);
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalEditTypeText]);

	return (
		<Modal
			title="Edit Course"
			open={toggleModalEditTypeText.open}
			onCancel={() => {
				setToggleModalEditTypeText({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					size="large"
					key={1}
					onClick={() => {
						setToggleModalEditTypeText({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					size="large"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={loadingEmail}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="type_of_text">
					<FloatInput label="Type of Text" placeholder="Course" required />
				</Form.Item>
			</Form>
		</Modal>
	);
}
