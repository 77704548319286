import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	Col,
	Form,
	Row,
	Typography,
	Upload,
	message,
	notification,
} from "antd";

import ModalAddAuthor from "../PageThesis/components/ModalAddAuthor";
import ThesisFormAuthor from "./PageThesisFormComponents/ThesisFormAuthor";
import FloatInput from "../../../providers/FloatInput";
import FloatDatePicker from "../../../providers/FloatDatePicker";
import FloatSelect from "../../../providers/FloatSelect";
import FloatTextArea from "../../../providers/FloatTextArea";

import { GET, POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";
import optionType from "../../../providers/optionType";

import ModalAttachment from "./components/ModalAttachment";

import { InboxOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowUpFromBracket,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import ModalEditAuthor from "./components/ModalEditAuthor";
import validateRules from "../../../providers/validateRules";

const { Title } = Typography;

export default function PageThesisAdd() {
	const location = useLocation();
	const params = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const { Dragger } = Upload;

	const [toggleModalAttachment, SetToggleModalAttachment] = useState({
		open: false,
		data: null,
	});

	const [toggleModalAddAuthor, setToggleModalAddAuthor] = useState({
		open: false,
		data: null,
		book_id: null,
	});

	const { data: dataBooks } = GET(`api/books`, "books_list");

	const { data: department } = GET(`api/ref_departments`, "ref_departments");
	const { data: type } = GET(`api/ref_text_types`, "ref_text_types");
	const { data: university } = GET(`api/ref_universities`, "ref_universities");

	const { data: authors, refetch: refetchAuthor } = GET(
		`api/profiles`,
		"profiles"
	);

	const { mutate: mutatethesis, loading: loadingthesis } = POST(
		`api/books`,
		"book"
	);

	const propsUpload = {
		accept: "application/pdf",
		beforeUpload: (file) => {
			return false;
		},
	};

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const onFinish = (values) => {
		console.log("onFinish values", values);
		let data = new FormData();

		// data.append("id", params.id ? params.id : "");

		// Add Book Information
		data.append("department_id", values.department_id);
		data.append("bookname", values.bookname);
		if (values.datepublish) {
			if (dayjs(values.datepublish).isValid()) {
				data.append(
					"datepublish",
					dayjs(values.datepublish).format("YYYY-MM-DD")
				);
			} else {
				data.append("datepublish", values.datepublish);
			}
		} else {
			data.append("datepublish", "");
		}
		data.append("type_id", values.type_id);
		data.append("university_id", values.university_id);
		data.append("abstract", values.abstract);
		data.append("author_list", JSON.stringify(values.profile_id));
		data.append("action", "add");

		if (values.pdf_file && values.pdf_file.file) {
			data.append("pdf_file", values.pdf_file.file, values.pdf_file.file.name);
		}

		// add author information

		// Notification
		mutatethesis(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Thesis Book Information",
						description: res.message,
					});

					navigate(`/thesis/books`);
				} else {
					notification.error({
						message: "Thesis Book Information",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<>
			<Form
				onFinish={onFinish}
				form={form}
				initialValues={{ author_list: [{}] }}
			>
				<Card>
					<div>
						<Title level={5} style={{ marginTop: "5px" }}>
							Book Information
						</Title>
					</div>
					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="department_id" rules={[validateRules.required]}>
								<FloatSelect
									label="Department"
									placeholder="Department"
									required
									options={
										department
											? department.data.map((item) => ({
													value: item.id,
													label: item.department_name,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="bookname" rules={[validateRules.required]}>
								<FloatInput
									label="Book Title"
									placeholder="Book Title"
									required
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item name="abstract" rules={[validateRules.required]}>
								<FloatTextArea
									label="Abstract"
									placeholder="Abstract"
									required
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="datepublish" rules={[validateRules.required]}>
								<FloatDatePicker
									label="Year Published"
									placeholder="Year Published"
									required
									format="YYYY-MM"
									picker="month"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="type_id" rules={[validateRules.required]}>
								<FloatSelect
									label="Type of Text"
									placeholder="Type of Text"
									required
									options={
										type
											? type.data.map((item) => ({
													value: item.id,
													label: item.type_of_text,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12}>
							<Form.Item name="university_id" rules={[validateRules.required]}>
								<FloatSelect
									label="University"
									placeholder="University"
									required
									options={
										university
											? university.data.map((item) => ({
													value: item.id,
													label: item.university_name,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={(12, 12)} style={{ marginTop: "20px" }}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item name="pdf_file" valuePropName="filelist">
								<Upload {...propsUpload}>
									<Button
										icon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
									>
										Upload File PDF
									</Button>
								</Upload>
							</Form.Item>
						</Col>
					</Row>
				</Card>
				<Card style={{ marginTop: "20px" }}>
					<div>
						<Title level={5} style={{ marginTop: "5px" }}>
							Author Information
						</Title>
					</div>
					<Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={18}>
							<Form.Item name="profile_id">
								<FloatSelect
									label="Authors"
									placeholder="Authors"
									required
									multi="multiple"
									onChange={handleChange}
									options={
										authors
											? authors.data.map((item) => ({
													value: item.id,
													label: `${item.firstname} ${
														item.middlename ? item.middlename + " " : ""
													}${item.lastname}`,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>
						<Button
							className="btn-main-primary btn-main-invert-outline b-r-none"
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={() =>
								setToggleModalAddAuthor({
									open: true,
								})
							}
							size="large"
							name="btn_add"
						>
							Add Another Author
						</Button>
					</Row>
				</Card>

				{/* <Row gutter={(12, 12)}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<ThesisFormAuthor />
						</Col>
					</Row> */}

				<ModalAttachment
					toggleModalAttachment={toggleModalAttachment}
					SetToggleModalAttachment={SetToggleModalAttachment}
				/>

				<ModalAddAuthor
					toggleModalAddAuthor={toggleModalAddAuthor}
					setToggleModalAddAuthor={setToggleModalAddAuthor}
					form1={form}
					refetchAuthor={refetchAuthor}
				/>

				<Button
					htmlType="submit"
					className="btn-main-primary w-10 w-xs-100"
					style={{ marginTop: "10px" }}
				>
					Submit
				</Button>
			</Form>
		</>
	);
}
